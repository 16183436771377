import _defineProperty from "/codebuild/output/src2417502137/src/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { createHttpLink } from "apollo-link-http";
import { setContext } from "@apollo/client/link/context";
import ApolloClient from "apollo-client";
import { ApolloLink } from "apollo-link";
import { InMemoryCache } from "apollo-cache-inmemory";
import * as https from "https";
export default function client(initialState) {
  var myLink = createHttpLink({
    // uri: "http://127.0.0.1/graphql/",
    uri: "https://api.cottagecentre.nl/graphql/",
    fetchOptions: {
      agent: new https.Agent({
        rejectUnauthorized: false
      })
    }
  });
  var thirdPartyLink = createHttpLink({
    uri: 'https://3xryzxchnzcn5j4bitqvdhzm5u.appsync-api.eu-central-1.amazonaws.com/graphql' // other link options...

  });
  var turboLink = createHttpLink({
    uri: 'http://127.0.0.1:4000' // other link options...

  });
  var authLink = setContext(function (_, _ref) {
    var headers = _ref.headers;
    return {
      headers: _objectSpread(_objectSpread({}, headers), {}, {
        'x-api-key': 'da2-vqse3i2p4fhd3ddzgykqbgxgqa'
      })
    };
  });
  return new ApolloClient({
    link: ApolloLink.split(function (operation) {
      return operation.getContext().clientName === "turbo";
    }, authLink.concat(turboLink), // <= apollo will send to this if clientName is "third-party"
    myLink // <= otherwise will send to this
    ),
    cache: new InMemoryCache().restore(initialState || {})
  });
}