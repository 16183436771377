import { v4 as uuidv4 } from 'uuid';

export const getSessionId = (): string => {
    if (typeof localStorage === 'undefined') {
        return null;
    }

    if (typeof localStorage === null) {
        return null;
    }

    const uuid = localStorage.getItem('uuid');

    if (uuid) {
        return uuid;
    }

    const newUuid = uuidv4();
    localStorage.setItem('uuid', newUuid);

    return newUuid;
}
