import {FilterInput} from "./graphql/filterInput";
import moment from "moment";
moment.locale('nl');

interface PropertiesForSearchLink {
    urlPath: string,
    urlParams: {
        'aankomst-datum'?: string,
        'eind-datum'?: string,
        'datum-flexibiliteit'?: number,
        'aantal-volwassenen'?: number,
        'aantal-kinderen'?: number,
        'aantal-babies'?: number,
        'aantal-honden'?: number,
        'aantal-nachten'?: number,
        'pagina'?: number,
        'lat'?: string,
        'lon'?: string,
        'afstand'?: number,
        'filters'?: string,
        'zoekopdracht'?: string,
        'destination'?: string,
    }
}


export class FilterSelection {
    startDate?: string;
    endDate?: string;
    dateFlexibility?: number;
    destinationSeoId?: string;
    adults?: number;
    children?: number;
    babies?: number
    dogs?: number;
    facilitySeoIds?: string[];
    query?: string;
    showMap?: number;
    pagina?: number;
    publishedAfter?: string;

    static fromUrlParams(router): FilterSelection {
        const params = router.query;
        const urlFilterSelection = {
            destinationSeoId: params.destination ? params.destination.toString() : undefined,
            adults: params['aantal-volwassenen'] ? parseInt(params['aantal-volwassenen'].toString()) : undefined,
            children: params['aantal-kinderen'] ? parseInt(params['aantal-kinderen'].toString()) : undefined,
            babies: params['aantal-babies'] ? parseInt(params['aantal-babies'].toString()) : undefined,
            dogs: params['aantal-honden'] ? parseInt(params['aantal-honden'].toString()) : undefined,
            startDate: params['aankomst-datum'] ? params['aankomst-datum'].toString() : undefined,
            endDate: params['eind-datum'] ? params['eind-datum'].toString() : undefined,
            dateFlexibility: params['datum-flexibiliteit'] ? parseInt(params['datum-flexibiliteit']) : undefined,
            query: params['zoekopdracht'] ? params['zoekopdracht'].toString().toLowerCase() : undefined,
            showMap: params['toon-kaart'] ? params['toon-kaart'] : undefined,
            pagina: params['pagina'] ? params['pagina'] : undefined,
            publishedAfter: params['toegevoegd-na'] ? params['toegevoegd-na'] : undefined,
            facilitySeoIds: params['filters']
                ? params['filters']
                    .toString()
                    .split(',')
                : undefined
        }

        if (params['lat'] && params['lon']) {
            urlFilterSelection['nearbyCoordinates'] = {
                lat: params['lat'],
                lon: params['lon'],
                distance: params['afstand'],
            }
        }

        return FilterSelection.deserialize(urlFilterSelection);
    }

    static deserialize(data): FilterSelection {
        const searchSelection = new FilterSelection();
        if (! data) {
            return searchSelection;
        }

        searchSelection.adults = data.adults;
        searchSelection.children = data.children;
        searchSelection.babies = data.babies;
        searchSelection.dogs = data.dogs;
        searchSelection.destinationSeoId = data.destinationSeoId;
        searchSelection.startDate =  data.startDate;
        searchSelection.endDate =  data.endDate;
        searchSelection.dateFlexibility = data.dateFlexibility;
        searchSelection.facilitySeoIds = data.facilitySeoIds;
        searchSelection.query = data.query;
        searchSelection.showMap = data.showMap;
        searchSelection.pagina = data.pagina;
        searchSelection.publishedAfter = data.publishedAfter;

        if (data.filters) {
           searchSelection.facilitySeoIds = data.filters.map(filter => filter.seoId);
        }

        return searchSelection;
    }

    serialize = (): FilterInput => {
        return {
            adults: this.adults,
            children: this.children,
            babies: this.babies,
            dogs: this.dogs,
            destinationSeoId: this.destinationSeoId,
            startDate: this.startDate,
            endDate: this.endDate,
            dateFlexibility: this.dateFlexibility ?? 1,
            facilitySeoIds: this.facilitySeoIds,
            query: this.query,
            publishedAfter: this.publishedAfter
            // showMap: this.showMap

        }
    }

    toGraphQLFilterInput = (): FilterInput => {
        return this.serialize();
    }

    getPropertiesForLink = (): PropertiesForSearchLink => {
        const params = {
            'aankomst-datum': this.startDate,
            'eind-datum': this.endDate,
            'datum-flexibiliteit': this.dateFlexibility === 0 ? 0 : undefined,
            'aantal-volwassenen': this.adults,
            'aantal-kinderen': this.children,
            'aantal-babies': this.babies,
            'aantal-honden': this.dogs,
            'zoekopdracht': this.query,
            'toon-kaart': this.showMap,
            'pagina': this.pagina,
            'filters': (this.facilitySeoIds && this.facilitySeoIds.length > 0) ? this.facilitySeoIds.join(',') : undefined,
        }
        Object.keys(params).forEach(key => params[key] === undefined || params[key] === null ? delete params[key] : {});

        let pathname = '/vakantiehuis';
        if (this.destinationSeoId) {
            pathname = `/vakantiehuis/${this.destinationSeoId}`;
        }

        return {
            urlPath: pathname,
            urlParams: params
        }
    }

    toggleFilter(filter: string): void {
        if (this.containsFilter(filter)) {
            this.removeFilter(filter);
            return;
        }

        this.addFilter(filter);
    }

    containsFilter(filter: string): boolean {
        if (!this.facilitySeoIds) {
            return false;
        }

        return this.facilitySeoIds.find(selectedFilter => selectedFilter === filter) !== undefined;
    }

    addFilter(filter: string): void {
        if (!this.facilitySeoIds) {
            this.facilitySeoIds = [filter];
            return;
        }

        // To prevent doubles, first remove the filter if it already exist
        const newFilter = this.facilitySeoIds.filter(item => item !== filter);
        newFilter.push(filter)
        this.facilitySeoIds = newFilter;
    }

    removeFilter(filter: string): void {
        this.facilitySeoIds = this.facilitySeoIds.filter(item => item !== filter);
    }

    getAmountOfNights = (): number | null => {
        if (!this.endDate) {
            return null;
        }

        return moment(this.endDate).diff(moment(this.startDate), 'days');
    }

    hasRangeSelection = (): boolean => {
        return this.startDate !== null && this.endDate !== null;
    }
}
